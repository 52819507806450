

























































































































import { IUser, User } from '@/shared/model/user.model'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { nanoid } from 'nanoid'

export default Vue.extend({
  data: () => ({
    user: new User(),
    showPassword: false,
    breadcrumbItems: [] as any[],

    passwordChanged: false,

    passwordNew: '',
    passwordRepeat: '',
    passwordRepeatError: '',
  }),
  computed: {
    ...mapGetters({
      account: 'account',
    }),
    maxDate () {
      const now = new Date()
      const date10YearsAgo = new Date()
      date10YearsAgo.setFullYear(now.getFullYear() - 10)
      return date10YearsAgo.toISOString()
    },
  },
  watch: {
    $route: function () {
      this.breadcrumbItems = []
    },
  },
  mounted () {},
  methods: {
    ...mapActions('userStore', {
      updateUser: 'updateItem',
    }),
    async clickSave () {
      if (this.checkPasswords()) {
        this.account.password = this.passwordNew
        this.updateUser({ id: this.account._id, item: this.account }).then(result => {
          this.passwordChanged = true
        })
      }
    },
    clickNewPassword () {
      this.passwordNew = nanoid(8)
    },
    checkPasswords () {
      if (this.passwordNew === this.passwordRepeat) {
        return true
      } else {
        this.passwordRepeatError = 'Die Passwörter stimmen nicht überein'
        return false
      }
    },
  },
})
